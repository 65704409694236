export default {
    apiKey: "AIzaSyA5TY5nUehT062zgF7kvbVSCVzsrwcRF2c",
    authDomain: "anitrace-45c99.firebaseapp.com",
    projectId: "anitrace-45c99",
    storageBucket: "anitrace-45c99.appspot.com",
    messagingSenderId: "257438109649",
    appId: "1:257438109649:web:efdff3ea100fa3e8819af0"
}

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyA5TY5nUehT062zgF7kvbVSCVzsrwcRF2c",
//   authDomain: "anitrace-45c99.firebaseapp.com",
//   projectId: "anitrace-45c99",
//   storageBucket: "anitrace-45c99.appspot.com",
//   messagingSenderId: "257438109649",
//   appId: "1:257438109649:web:efdff3ea100fa3e8819af0"
// };

// // Initialize Firebase
// export default initializeApp(firebaseConfig);

